.homeCard {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.homeCardTitle {
    color: #36b49f;
}

.buttonHome {
    background-color: #36b49f;
    color: #FFFFFF;
    border: 1px solid #36b49f;
}
    .buttonHome:active {
        transform: scale(0.95);
    }

    .buttonHome:focus {
        outline: none;
    }

.gridContainer {
    display: grid;
    grid-template-columns: repeat(7,auto);
    column-gap: 5px;
    row-gap: 15px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(1,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(1,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(2,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(3,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(4,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(5,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}

@media only screen and (min-width: 1500px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(6,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(7,auto);
        column-gap: 15px;
        row-gap: 15px;
    }
}

