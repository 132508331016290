.spinner {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 100000000000;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  }
  
  .spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }