

.button-item-result {
    background-color: #36b49f;
    color: #FFFFFF;
    border: 1px solid #36b49f;
}

    .button-item-result:active {
        transform: scale(0.95);
    }

    .button-item-result:focus {
        outline: none;
    }

.item-resultado-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 15px;
    font-size:14px;
    width:100%;
}

.bg-danger {
    background-color: #FB9678 !important;
}

.bg-warning {
    background-color: #FFB463 !important;
}

.badge {
    font-size:0.90em;
}
