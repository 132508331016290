@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
* {
    
}

body {
    background: #f6f5f7;
    font-family: 'Montserrat', sans-serif;
}

.login-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh !important;
    margin: -20px 0 50px;
}
    

.h1-login {
    font-weight: bold;
    margin: 0;
}

.h2-login {
    text-align: center;
}

.p-login {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}

.a-login {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.button-login {
    border-radius: 20px;
    border: 1px solid #36b49f;
    background-color: #36b49f;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

    .button-login:active {
        transform: scale(0.95);
    }

    .button-login:focus {
        outline: none;
    }

    .button-login.ghost {
        background-color: transparent;
        border-color: #FFFFFF;
    }

.form-login {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.input-login {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
}

.container-login {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 900px;
    max-width: 100%;
    min-height: 550px;
}

.form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
}

.container.container-login.right-panel-active .sign-in-container {
    transform: translateX(100%);
}

.sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
}

.container.container-login.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
}

@keyframes show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.container-login.right-panel-active .overlay-container {
    transform: translateX(-100%);
}

.overlay {
    background: #36b49f;
    background: linear-gradient(to right, #73E0CF, #36b49f);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.container-login.right-panel-active .overlay {
    transform: translateX(50%);
}

.overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay-left {
    transform: translateX(-20%);
}

.container.container-login.right-panel-active .overlay-left {
    transform: translateX(0);
}

.overlay-right {
    right: 0;
    transform: translateX(0);
}

.container.container-login.right-panel-active .overlay-right {
    transform: translateX(20%);
}

.social-container {
    margin: 20px 0;
}

    .social-container a {
        border: 1px solid #DDDDDD;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        height: 40px;
        width: 40px;
    }

.footer-login {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

    .footer-login p {
        margin: 10px 0;
    }

    .footer-login i {
        color: red;
    }

    .footer-login a {
        color: #3c97bf;
        text-decoration: none;
    }

@media only screen and (max-width: 600px) {
    .container-login {
        display: flex;
        align-items: center; /*horizontal centering*/
        justify-content: center; /*vertical centering*/
        flex-direction: column; /*keep the h3 above the textbox*/
        transition: transform 0.6s ease-in-out;
    }

    .overlay-container {
        position: relative;
        left: 0;
        width: 100%;
        order: 1;
        transition: transform 0.6s ease-in-out;
    }

    .sign-in-container {
        width: 100%;
        transition: transform 0.6s ease-in-out;
        order: 2;
    }

    .form-container {
        position: relative;
    }

    .form-login {
        padding: 15px;
    }
    
}

a.navbar-brand {
    white-space: normal;
    text-align: left;
    word-break: break-all;
    color: white !important;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-colors {
    background-color: #36b49f;
    
}

.spinner {
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 100000000000;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
  }
  
  .spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }


.button-item-result {
    background-color: #36b49f;
    color: #FFFFFF;
    border: 1px solid #36b49f;
}

    .button-item-result:active {
        transform: scale(0.95);
    }

    .button-item-result:focus {
        outline: none;
    }

.item-resultado-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 15px;
    font-size:14px;
    width:100%;
}

.bg-danger {
    background-color: #FB9678 !important;
}

.bg-warning {
    background-color: #FFB463 !important;
}

.badge {
    font-size:0.90em;
}

.home_homeCard__3bhuv {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.home_homeCardTitle__7OAmP {
    color: #36b49f;
}

.home_buttonHome__cmoc3 {
    background-color: #36b49f;
    color: #FFFFFF;
    border: 1px solid #36b49f;
}
    .home_buttonHome__cmoc3:active {
        transform: scale(0.95);
    }

    .home_buttonHome__cmoc3:focus {
        outline: none;
    }

.home_gridContainer___eJfH {
    display: grid;
    grid-template-columns: repeat(7,auto);
    grid-column-gap: 5px;
    column-gap: 5px;
    grid-row-gap: 15px;
    row-gap: 15px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(1,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(1,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(2,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(3,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}


/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(4,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(5,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}

@media only screen and (min-width: 1500px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(6,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1800px) {
    .home_gridContainer___eJfH {
        display: grid;
        grid-template-columns: repeat(7,auto);
        grid-column-gap: 15px;
        column-gap: 15px;
        grid-row-gap: 15px;
        row-gap: 15px;
    }
}


.profile_user_change_password_homeCard__1eqte {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.profile_user_change_password_homeCardTitle__3TaZ0 {
    color: #36b49f;
}

.profile_user_change_password_buttonHome__qnY5Z {
    background-color: #36b49f;
    color: #FFFFFF;
    border: 1px solid #36b49f;
}

    .profile_user_change_password_buttonHome__qnY5Z:active {
        transform: scale(0.95);
    }

    .profile_user_change_password_buttonHome__qnY5Z:focus {
        outline: none;
    }
