a.navbar-brand {
    white-space: normal;
    text-align: left;
    word-break: break-all;
    color: white !important;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-colors {
    background-color: #36b49f;
    
}
