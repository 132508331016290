.homeCard {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.homeCardTitle {
    color: #36b49f;
}

.buttonHome {
    background-color: #36b49f;
    color: #FFFFFF;
    border: 1px solid #36b49f;
}

    .buttonHome:active {
        transform: scale(0.95);
    }

    .buttonHome:focus {
        outline: none;
    }